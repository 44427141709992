import { LamieStepperPageBaseComponent } from './lamie-stepper-page-base.component';
import { computed, signal } from '@angular/core';

export class LamieStepperService {
  private _currentPage = signal<LamieStepperPageBaseComponent | null>(null);
  currentPage = computed(() => this._currentPage());

  registerPage(page: LamieStepperPageBaseComponent) {
    this._currentPage.set(page);
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ControlBaseAbstractDirective,
  formViewProvider,
} from './control-base-abstract.directive';

import { TranslateModule } from '@ngx-translate/core';

export type SelectBoxOption = {
  value: string;
  label: string;
};

@Component({
  selector: 'integral-select-box',
  standalone: true,
  template: `
    <div class="flex flex-col items-start">
      @if (cfg.label) {
        <label class="block mb-2" [for]="cfg.name">
          {{ cfg.label | translate }}
        </label>
      }
      <select
        [id]="cfg.name"
        [name]="cfg.name"
        [formControlName]="cfg.name"
        class="border border-gray-300 rounded w-full py-2 px-3 text-gray-900 leading-tight"
      >
        @if (cfg.placeholder) {
          <option [id]="cfg.name + '-placeholder'" value="" disabled>
            {{ cfg.placeholder | translate }}
          </option>
        }
        @for (option of options; track option.value) {
          <option [id]="option.value" [value]="option.value">
            {{ option.label | translate }}
          </option>
        }
      </select>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, TranslateModule],
  viewProviders: [formViewProvider], // needed so this can be registered to a parent form
})
export class SelectBoxComponent extends ControlBaseAbstractDirective {
  @Input({ required: true }) options: SelectBoxOption[] = [];
}

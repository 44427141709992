/**
 * File Services API
 * The File Services API provides methods for creating and managing files.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';


// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface FinalizeUploadRequestParams {
    product: string;
    fileName: string;
    totalChunks: number;
    lamieToken: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
    requestBody: { [key: string]: string; };
}

export interface RemoveUploadRequestParams {
    product: string;
    fileName: string;
    lamieToken: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
}

export interface UploadChunkRequestParams {
    product: string;
    fileName: string;
    chunkIndex: number;
    lamieToken: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
    fileChunk?: Blob;
}


@Injectable({
  providedIn: 'root'
})
export class FileAggregationApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public finalizeUpload(requestParameters: FinalizeUploadRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public finalizeUpload(requestParameters: FinalizeUploadRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public finalizeUpload(requestParameters: FinalizeUploadRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public finalizeUpload(requestParameters: FinalizeUploadRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling finalizeUpload.');
        }
        const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling finalizeUpload.');
        }
        const totalChunks = requestParameters.totalChunks;
        if (totalChunks === null || totalChunks === undefined) {
            throw new Error('Required parameter totalChunks was null or undefined when calling finalizeUpload.');
        }
        const lamieToken = requestParameters.lamieToken;
        if (lamieToken === null || lamieToken === undefined) {
            throw new Error('Required parameter lamieToken was null or undefined when calling finalizeUpload.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling finalizeUpload.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling finalizeUpload.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling finalizeUpload.');
        }
        const requestBody = requestParameters.requestBody;
        if (requestBody === null || requestBody === undefined) {
            throw new Error('Required parameter requestBody was null or undefined when calling finalizeUpload.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (fileName !== undefined && fileName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileName, 'fileName');
        }
        if (totalChunks !== undefined && totalChunks !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>totalChunks, 'totalChunks');
        }

        let localVarHeaders = this.defaultHeaders;
        if (lamieToken !== undefined && lamieToken !== null) {
            localVarHeaders = localVarHeaders.set('lamie-token', String(lamieToken));
        }
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/file/api/v1/claims/FinalizeUpload`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: requestBody,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeUpload(requestParameters: RemoveUploadRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public removeUpload(requestParameters: RemoveUploadRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public removeUpload(requestParameters: RemoveUploadRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public removeUpload(requestParameters: RemoveUploadRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling removeUpload.');
        }
        const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling removeUpload.');
        }
        const lamieToken = requestParameters.lamieToken;
        if (lamieToken === null || lamieToken === undefined) {
            throw new Error('Required parameter lamieToken was null or undefined when calling removeUpload.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling removeUpload.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling removeUpload.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling removeUpload.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (fileName !== undefined && fileName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileName, 'fileName');
        }

        let localVarHeaders = this.defaultHeaders;
        if (lamieToken !== undefined && lamieToken !== null) {
            localVarHeaders = localVarHeaders.set('lamie-token', String(lamieToken));
        }
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/file/api/v1/claims/RemoveUpload`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadChunk(requestParameters: UploadChunkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any>;
    public uploadChunk(requestParameters: UploadChunkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpResponse<any>>;
    public uploadChunk(requestParameters: UploadChunkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<HttpEvent<any>>;
    public uploadChunk(requestParameters: UploadChunkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined, context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling uploadChunk.');
        }
        const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling uploadChunk.');
        }
        const chunkIndex = requestParameters.chunkIndex;
        if (chunkIndex === null || chunkIndex === undefined) {
            throw new Error('Required parameter chunkIndex was null or undefined when calling uploadChunk.');
        }
        const lamieToken = requestParameters.lamieToken;
        if (lamieToken === null || lamieToken === undefined) {
            throw new Error('Required parameter lamieToken was null or undefined when calling uploadChunk.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling uploadChunk.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling uploadChunk.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling uploadChunk.');
        }
        const fileChunk = requestParameters.fileChunk;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (fileName !== undefined && fileName !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>fileName, 'fileName');
        }
        if (chunkIndex !== undefined && chunkIndex !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>chunkIndex, 'chunkIndex');
        }

        let localVarHeaders = this.defaultHeaders;
        if (lamieToken !== undefined && lamieToken !== null) {
            localVarHeaders = localVarHeaders.set('lamie-token', String(lamieToken));
        }
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (fileChunk !== undefined) {
            localVarFormParams = localVarFormParams.append('fileChunk', <any>fileChunk) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/file/api/v1/claims/UploadChunk`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import {
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ClaimApi } from '@integral/shared/backends/claim';
import { FileApi } from '@integral/shared/backends/file';

export const httpAuthInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  const authService = inject(AuthService);
  const claimApiConfig = inject(ClaimApi.Configuration);
  const fileApiConfig = inject(FileApi.Configuration);

  const claimApiBasePath = claimApiConfig.basePath ?? '';
  const fileApiBasePath = fileApiConfig.basePath ?? '';

  if (
    request.headers.has('Authorization') ||
    request.url === authService.authUrl ||
    (!request.url.includes(claimApiBasePath) &&
      !request.url.includes(fileApiBasePath))
  ) {
    // skip if already present or the auth request itselct
    return next(request);
  }

  return authService.getAuth().pipe(
    switchMap((authResponse) => {
      const authenticatedRequest = request.clone({
        headers: request.headers.set(
          'Authorization',
          `${authResponse.token_type} ${authResponse.access_token}`,
        ),
      });
      return next(authenticatedRequest);
    }),
  );
};

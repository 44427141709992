import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  Input,
  Provider,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';

export interface IFocusable {
  focus(): void;
}

export type ControlBaseConfig = {
  name: string;
  label?: string;
  placeholder?: string;
};

export function controlContainerFactory(
  ctrlContainer: ControlContainer,
): ControlContainer {
  return ctrlContainer;
}

export const formViewProvider: Provider = {
  provide: ControlContainer,
  useFactory: controlContainerFactory,
  deps: [[new SkipSelf(), ControlContainer]],
};

@Directive()
export abstract class ControlBaseAbstractDirective {
  protected cdr = inject(ChangeDetectorRef);

  @ViewChild('focusableControl') protected focusableControl:
    | ElementRef
    | IFocusable
    | undefined;

  @Input({ required: true }) public cfg!: ControlBaseConfig;

  public focus(): void {
    if (this.focusableControl instanceof ElementRef) {
      this.focusableControl.nativeElement?.focus();
    } else {
      this.focusableControl?.focus();
    }
  }
}

import { Injectable, signal } from '@angular/core';

@Injectable()
export class LoadingOverlayStateService {
  loadingState = signal(false);

  showGlobalLoading(): void {
    this.loadingState.set(true);
  }
  hideGlobalLoading(): void {
    this.loadingState.set(false);
  }
}

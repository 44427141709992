export function getAllUniqueCurrencies(): string[] {
  const currencies: { [index: string]: string } = {};
  Object.values(allCountriesWithCurrencies).forEach(
    (elem) => (currencies[elem] = elem),
  );
  return Object.keys(currencies).sort((a, b) => (a > b ? 1 : -1));
}

export const allCountriesWithCurrencies: { [index: string]: string } = {
  AF: 'AFN',
  AX: 'EUR',
  AL: 'ALL',
  DZ: 'DZD',
  AD: 'EUR',
  AO: 'AOA',
  AI: 'XCD',
  AG: 'XCD',
  AR: 'ARS',
  AM: 'AMD',
  AW: 'AWG',
  AZ: 'AZN',
  AU: 'AUD',
  BS: 'BSD',
  BH: 'BHD',
  BD: 'BDT',
  BB: 'BBD',
  BY: 'BYN',
  BE: 'EUR',
  BZ: 'BZD',
  BJ: 'XOF',
  BM: 'BMD',
  BT: 'BTN',
  BO: 'BOV',
  'St. Eustatius und Saba': 'USD',
  BA: 'BAM',
  BW: 'BWP',
  BV: 'NOK',
  BR: 'BRL',
  IO: 'USD',
  BN: 'BND',
  BG: 'BGN',
  BF: 'XOF',
  BI: 'BIF',
  CL: 'CLF',
  CN: 'CNY',
  CK: 'NZD',
  CR: 'CRC',
  CI: 'XOF',
  CW: 'ANG',
  DE: 'EUR',
  DM: 'XCD',
  DO: 'DOP',
  DJ: 'DJF',
  DK: 'DKK',
  EC: 'USD',
  SV: 'SVC',
  ER: 'ERN',
  EE: 'EUR',
  SZ: 'ZAR',
  FO: 'DKK',
  FK: 'FKP',
  FJ: 'FJD',
  FI: 'EUR',
  FR: 'EUR',
  TF: 'EUR',
  GF: 'EUR',
  PF: 'XPF',
  GA: 'XAF',
  GM: 'GMD',
  GE: 'GEL',
  GH: 'GHS',
  GI: 'GIP',
  GD: 'XCD',
  GR: 'EUR',
  GB: 'GBP',
  GL: 'DKK',
  GP: 'EUR',
  GU: 'USD',
  GT: 'GTQ',
  GG: 'GBP',
  GN: 'GNF',
  GW: 'XOF',
  GY: 'GYD',
  HT: 'USD',
  HM: 'AUD',
  HN: 'HNL',
  HK: 'HKD',
  IN: 'INR',
  ID: 'IDR',
  IQ: 'IQD',
  IR: 'IRR',
  IE: 'EUR',
  IS: 'ISK',
  IL: 'ILS',
  IT: 'EUR',
  JM: 'JMD',
  JP: 'JPY',
  YE: 'YER',
  JE: 'GBP',
  JO: 'JOD',
  VG: 'USD',
  VI: 'USD',
  KY: 'KYD',
  KH: 'KHR',
  CM: 'XAF',
  CA: 'CAD',
  CV: 'CVE',
  KZ: 'KZT',
  QA: 'QAR',
  KE: 'KES',
  KG: 'KGS',
  KI: 'AUD',
  CC: 'AUD',
  CO: 'COU',
  KM: 'KMF',
  CG: 'XAF',
  'Demokratische Republik': 'CDF',
  'Demokratische Volksrepublik': 'KPW',
  Republik: 'MDL',
  XK: 'EUR',
  HR: 'EUR',
  CU: 'CUC',
  KW: 'KWD',
  LA: 'LAK',
  LS: 'ZAR',
  LV: 'EUR',
  LB: 'LBP',
  LR: 'LRD',
  LY: 'LYD',
  LI: 'CHF',
  LT: 'EUR',
  LU: 'EUR',
  MO: 'MOP',
  MG: 'MGA',
  MW: 'MWK',
  MY: 'MYR',
  MV: 'MVR',
  ML: 'XOF',
  MT: 'EUR',
  Insel: 'GBP',
  'N�rdliche': 'USD',
  MA: 'MAD',
  MH: 'USD',
  MQ: 'EUR',
  MR: 'MRU',
  MU: 'MUR',
  YT: 'EUR',
  MX: 'MXN',
  FM: 'USD',
  MC: 'EUR',
  MN: 'MNT',
  ME: 'EUR',
  MS: 'XCD',
  MZ: 'MZN',
  MM: 'MMK',
  NA: 'ZAR',
  NR: 'AUD',
  NP: 'NPR',
  NC: 'XPF',
  NZ: 'NZD',
  NI: 'NIO',
  NL: 'EUR',
  NE: 'XOF',
  NG: 'NGN',
  NU: 'NZD',
  NF: 'AUD',
  NO: 'NOK',
  OM: 'OMR',
  PK: 'PKR',
  PW: 'USD',
  PA: 'USD',
  PG: 'PGK',
  PY: 'PYG',
  PE: 'PEN',
  PH: 'PHP',
  PN: 'NZD',
  PL: 'PLN',
  PT: 'EUR',
  PR: 'USD',
  MK: 'MKD',
  RE: 'EUR',
  RW: 'RWF',
  RO: 'RON',
  RU: 'RUB',
  BL: 'EUR',
  MF: 'EUR',
  SB: 'SBD',
  ZM: 'ZMW',
  AS: 'USD',
  WS: 'WST',
  SM: 'EUR',
  ST: 'STN',
  SA: 'SAR',
  SE: 'SEK',
  CH: 'CHF',
  SN: 'XOF',
  RS: 'RSD',
  SC: 'SCR',
  SL: 'SLE',
  ZW: 'ZWL',
  SG: 'SGD',
  SK: 'EUR',
  SI: 'EUR',
  SO: 'SOS',
  ES: 'EUR',
  SJ: 'NOK',
  LK: 'LKR',
  SX: 'ANG',
  VC: 'XCD',
  SH: 'SHP',
  KN: 'XCD',
  LC: 'XCD',
  PM: 'EUR',
  SD: 'SDG',
  SR: 'SRD',
  SY: 'SYP',
  ZA: 'ZAR',
  SS: 'SSP',
  TJ: 'TJS',
  TW: 'TWD',
  'Vereinigte Republik': 'TZS',
  TH: 'THB',
  TL: 'USD',
  TG: 'XOF',
  TK: 'NZD',
  TO: 'TOP',
  TT: 'TTD',
  TD: 'XAF',
  CZ: 'CZK',
  TN: 'TND',
  TM: 'TMT',
  TC: 'USD',
  TV: 'AUD',
  TR: 'TRY',
  UM: 'USD',
  US: 'USD',
  UG: 'UGX',
  UA: 'UAH',
  HU: 'HUF',
  UY: 'UYI',
  UZ: 'UZS',
  VU: 'VUV',
  VA: 'EUR',
  VE: 'VES',
  AE: 'AED',
  VN: 'VND',
  WF: 'XPF',
  CX: 'AUD',
  EH: 'MRO',
  CF: 'XAF',
  CY: 'EUR',
  EG: 'EGP',
  GQ: 'XAF',
  ET: 'ETB',
  AT: 'EUR',
};

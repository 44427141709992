/**
 * Claim Services API
 * The Claim Services API provides methods for creating and managing claims.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Gender = 'Unknown' | 'Female' | 'Male' | 'Company';

export const Gender = {
    Unknown: 'Unknown' as Gender,
    Female: 'Female' as Gender,
    Male: 'Male' as Gender,
    Company: 'Company' as Gender
};


import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  ButtonComponent,
  LamieStepperPageBaseComponent,
  LoadingBarComponent,
} from '@integral/shared/ui/layout';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  finalize,
  of,
  Subject,
} from 'rxjs';
import {
  FileUploadComponent,
  SelectBoxComponent,
  TextInputComponent,
} from '@integral/shared/ui/form';
import { AsyncPipe, NgClass } from '@angular/common';
import { FileApi, FileApiHeadersService } from '@integral/shared/backends/file';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { filterNullish } from '@integral/shared/util';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NumberVerificationForm } from '@integral/shared/full-step-pages';

export type UploadedFilesForm = FormGroup<{
  upload: FormControl<File | undefined>;
  uploadedFiles: FormControl<string[]>;
}>;

@Component({
  selector: 'app-uploaded-files',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    ButtonComponent,
    SelectBoxComponent,
    TextInputComponent,
    AsyncPipe,
    FileUploadComponent,
    LoadingBarComponent,
    NgClass,
  ],
  template: `
    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <h1 class="text-primary">{{ 'uploadedFiles.title' | translate }}</h1>
      <p>{{ 'uploadedFiles.text' | translate }}</p>

      <integral-file-upload
        [cfg]="{
          name: 'upload',
          label: 'uploadArea.uploadInstruction' | translate
        }"
        [allowedExtensions]="allowedExtensions"
        [uploadedFileName]="
          currentFileName() || 'uploadArea.noChosenFileInfo' | translate
        "
        (fileSelected)="onFileSelected($event)"
        (fileDropped)="onDrop($event)"
      ></integral-file-upload>

      <div class="w-full lg:w-5/11 flex flex-wrap gap-8 justify-between">
        <integral-button
          [buttonStyle]="'Secondary'"
          [text]="'uploadedFiles.removeButtonLabel' | translate"
          [disabled]="
            loading() || removing() || !stepControl.controls.upload.value
          "
          (click)="onClickClearUpload()"
        />
        <integral-button
          [type]="'submit'"
          [disabled]="
            loading() || removing() || !stepControl.controls.upload.value
          "
          [text]="'uploadedFiles.uploadButtonLabel' | translate"
          (click)="onClickUpload($event)"
        />
      </div>

      @for (uploadedFile of uploadedFiles(); track $index) {
        <div
          class="flex flex-nowrap justify-between w-full lg:w-5/11 border border-dashed rounded p-5 my-5"
        >
          <div class=" w-5/6">
            <p class="flex flex-wrap justify-start w-5/6 wordBreak">
              {{ uploadedFile }}
            </p>
          </div>
          <div class="w-1/6 flex justify-end">
            <button (click)="removeFile($index, $event)">
              <svg width="16" height="16">
                <use href="/assets/svg/remove-x.svg#root" />
              </svg>
            </button>
          </div>
        </div>
      }
      <div class="w-full lg:w-5/11" [ngClass]="!loading() ? 'hidden' : ''">
        <p class="w-full text-center">
          {{ 'loadingFile' | translate }}
        </p>
        <integral-loading-bar />
      </div>
      <div class="w-full lg:w-5/11" [ngClass]="!removing() ? 'hidden' : ''">
        <p class="w-full text-center">
          {{ 'removingFile' | translate }}
        </p>
        <integral-loading-bar [color]="'red'" />
      </div>

      <input type="hidden" [formControlName]="'uploadedFiles'" />
    </form>
  `,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
    input[type='file'] {
      display: none;
    }
    .lessOpacity {
      opacity: 0.5;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadedFilesComponent
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  @Input({ required: true }) override stepControl!: UploadedFilesForm;
  private readonly destroyRef = inject(DestroyRef);
  @ViewChild('consentDialog') consentDialog?: ElementRef<HTMLDialogElement>;

  private readonly fileAggregationApiService = inject(
    FileApi.FileAggregationApiService,
  );
  private readonly fileApiHeadersService = inject(FileApiHeadersService);

  private readonly uploadClicked = new Subject<void>();
  protected readonly currentFile = new BehaviorSubject<File | undefined>(
    undefined,
  );

  private readonly removedClicked = new Subject<string>();

  private readonly removeFile$ = this.removedClicked.pipe(
    tap(() => {
      this.removing.set(true);
    }),
    switchMap((file) => {
      const lamieToken = this.getToken();
      const removeChunkRequestParams = {
        ...this.fileApiHeadersService.defaultHeaders,
        fileName: file,
        chunkIndex: 0,
        lamieToken,
        body: {},
      };
      return this.fileAggregationApiService
        .removeUpload(removeChunkRequestParams)
        .pipe(
          tap(() => {
            this.removing.set(false);
          }),
          catchError((e) => {
            this.removing.set(false);
            return of(e);
          }),
        );
    }),
  );

  private readonly uploadChunk$ = this.uploadClicked.pipe(
    tap(() => {
      this.loading.set(true);
    }),
    withLatestFrom(this.currentFile.pipe(filterNullish())),
    switchMap(([, file]) => {
      const lamieToken = this.getToken();

      const uploadChunkRequestParams: FileApi.UploadChunkRequestParams = {
        ...this.fileApiHeadersService.defaultHeaders,
        fileName: file.name,
        chunkIndex: 0,
        lamieToken,
        fileChunk: file,
      };
      this.currentFileName.set(file.name);
      return this.fileAggregationApiService
        .uploadChunk(uploadChunkRequestParams)
        .pipe(
          tap(() => {
            const uploadedFile = {
              fileName: file.name,
            };
            const allFiles = this.stepControl.controls.uploadedFiles.value;
            this.stepControl.controls.uploadedFiles.setValue([
              ...allFiles,
              uploadedFile.fileName,
            ]);
            this.loading.set(false);
          }),
          catchError((e) => {
            this.loading.set(false);
            return of(e);
          }),
          switchMap(() => {
            const finalizeRequestParams: FileApi.FinalizeUploadRequestParams = {
              ...this.fileApiHeadersService.defaultHeaders,
              fileName: file.name,
              totalChunks: 1,
              lamieToken,
              requestBody: {},
            };
            return this.fileAggregationApiService
              .finalizeUpload(finalizeRequestParams)
              .pipe(
                catchError((e) => {
                  return of(e);
                }),
                finalize(() => {
                  this.actionsOnError();
                }),
              );
          }),
        );
    }),
  );
  protected currentFileName: WritableSignal<undefined | string> =
    signal(undefined);
  protected uploadedFiles = signal([] as string[]);
  protected loading = signal(false);
  protected removing = signal(false);
  protected readonly allowedExtensions = '.pdf,.png,.jpg,.jpeg,.gif';
  constructor() {
    super();
    this.uploadChunk$.pipe(takeUntilDestroyed()).subscribe();
    this.removeFile$.pipe(takeUntilDestroyed()).subscribe();
  }

  override ngOnInit() {
    super.ngOnInit();
    const uploadedFilesFC = this.stepControl.controls.uploadedFiles;
    const uploadedFilesValue = this.stepControl.controls.uploadedFiles.value;
    this.uploadedFiles.set(uploadedFilesValue);
    uploadedFilesFC.valueChanges
      .pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef))
      .subscribe((files) => {
        this.uploadedFiles.set(files);
      });
    this.stepControl.controls.upload.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
        tap(() => this.setUploadProcessingStatus()),
      )
      .subscribe();
  }

  private getToken() {
    const step1Ctrl = this.stepControl.parent?.get('step1');
    if (!step1Ctrl) throw new Error('Ctrl for step1 unavailable');
    const step1FG = step1Ctrl as NumberVerificationForm;
    const lamieToken = step1FG.controls.token.value;
    if (!lamieToken) {
      this.actionsOnError();
      throw new Error('No token');
    }
    return lamieToken;
  }

  private actionsOnError() {
    this.loading.set(false);
    this.stepControl.controls.upload.reset();
    this.currentFile.next(undefined);
    this.currentFileName.set(undefined);
  }

  protected onClickUpload(e: Event): void {
    e.preventDefault();
    this.uploadClicked.next();
  }
  protected onDrop(e: Event): void {
    const dataTransfer = (e as DragEvent).dataTransfer;
    const files = dataTransfer?.files;
    if (!files) return;
    this.currentFile.next(files[0]);
    this.currentFileName.set(files[0].name);
  }

  protected onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    this.currentFile.next(files[0]);
    this.currentFileName.set(files[0].name);
  }

  removeFile(fileIndex: number, e: Event) {
    e.preventDefault();
    const filesList = this.stepControl.controls.uploadedFiles.value;
    this.removedClicked.next(filesList.splice(fileIndex, 1)[0]);
    this.stepControl.controls.uploadedFiles.setValue(filesList);
    this.currentFile.next(undefined);
    this.currentFileName.set(undefined);
  }

  override onPrev = () => {
    this.stepControl.controls.upload.reset();
    return true;
  };

  override onNext = () => {
    this.actionsOnError();
    return true;
  };

  onClickClearUpload() {
    if (!this.stepControl.controls.upload.value) return;
    this.stepControl.controls.upload.reset();
    this.currentFileName.set(undefined);
  }

  private setUploadProcessingStatus() {
    if (this.stepControl.controls.upload.value) {
      this.stepControl.controls.upload.setErrors({ uploadProcessing: true });
    } else {
      this.stepControl.controls.upload.setErrors(null);
    }
  }
}

export const allInvoiceTypes = {
  medicalTreatment: 'Medical treatment costs (doctors, hospital)',
  medication: 'Medication and pharmaceuticals',
  dentalServices: 'Dental services',
  transportation: 'Transportation costs',
  accommodation: 'Accommodation costs',
  accommodationAccompaniment: 'Accommodation costs for accompanying person',
  searchAndRescue: 'Search and Rescue service costs',
  repatriation: 'Repatriation costs',
  replacementMissingItems: 'Replacement costs for lost/stolen items',
  replacementMissingDocuments: 'Costs for re-issuance of lost/stolen documents',
  other: 'Other costs',
};

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ControlBaseAbstractDirective,
  formViewProvider,
} from './control-base-abstract.directive';
import { TranslateModule } from '@ngx-translate/core';

export interface RadioOption {
  value: string;
  label: string;
}

@Component({
  standalone: true,
  selector: 'integral-radio-group',
  template: `
    <div class="flex flex-col gap-2">
      @for (option of options; track option.value) {
        <div class="flex flex-row items-center gap-2">
          <input
            class="w-5 h-5 bg-white border-2 checked:border-[6px] checked:border-primary appearance-none rounded-full cursor-pointer"
            type="radio"
            [formControlName]="cfg.name"
            [id]="option.value"
            [value]="option.value"
          />

          <label [for]="option.value">{{ option.label | translate }}</label>
        </div>
      }
    </div>
  `,

  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule, TranslateModule],
  viewProviders: [formViewProvider], // needed so this can be registered to a parent form
})
export class RadioGroupComponent extends ControlBaseAbstractDirective {
  @Input({ required: true }) options: RadioOption[] = [];
}

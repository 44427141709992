import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ControlBaseAbstractDirective,
  formViewProvider,
} from './control-base-abstract.directive';

@Component({
  selector: 'integral-textarea',
  standalone: true,
  template: `
    @if (cfg.label) {
      <label class="block mb-2" [for]="cfg.name">{{ cfg.label }}</label>
    }
    <textarea
      [id]="cfg.name"
      rows="6"
      cols="50"
      class="border border-solid block px-4 py-2 rounded  border-gray-300 focus:border-gray-300 text-sm text-left placeholder-gray-400 w-full"
      [placeholder]="cfg.placeholder"
      [formControlName]="cfg.name"
    ></textarea>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule],
  viewProviders: [formViewProvider], // needed so this can be registered to a parent form
})
export class TextareaComponent extends ControlBaseAbstractDirective {}

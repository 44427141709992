/**
 * Claim Services API
 * The Claim Services API provides methods for creating and managing claims.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ClaimAffectedPersonType = 'ContractHolder' | 'ContractUser';

export const ClaimAffectedPersonType = {
    ContractHolder: 'ContractHolder' as ClaimAffectedPersonType,
    ContractUser: 'ContractUser' as ClaimAffectedPersonType
};


import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type Color = 'blue' | 'black' | 'red';
@Component({
  selector: 'integral-loading-bar',
  standalone: true,
  imports: [],
  template: `
    <div class="flex flex-col space-y-3">
      <div class="relative w-full bg-gray-200 rounded">
        @if (color === 'blue') {
          <div
            style="width: 80%"
            class="absolute top-0 h-4 rounded shim-blue"
          ></div>
        } @else if (color === 'red') {
          <div
            style="width: 100%"
            class="absolute top-0 h-4 rounded shim-red"
          ></div>
        } @else if (color === 'black') {
          <div
            style="width: 100%"
            class="absolute top-0 h-4 rounded shim-black"
          ></div>
        }
      </div>
    </div>
  `,
  styles: `
    :host {
      display: block;
    }
    .shim-black {
      position: relative;
      overflow: hidden;
      background-color: rgba(0, 0, 0, 0.7);
    }
    .shim-black::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(233, 233, 233, 1) 0,
        rgba(233, 233, 233, 0.9) 50%,
        rgba(233, 233, 233, 0.8) 100%
      );
      animation: shimmer 2s ease-out infinite;
      content: '';
    }

    .shim-blue {
      position: relative;
      overflow: hidden;
      background-color: rgba(15, 39, 252, 1);
    }
    .shim-blue::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(233, 233, 233, 1) 0,
        rgba(233, 233, 233, 0.9) 50%,
        rgba(233, 233, 233, 0.8) 100%
      );
      animation: shimmer 2.5s ease-out infinite;
      content: '';
    }

    .shim-red {
      position: relative;
      overflow: hidden;
      background-color: rgba(218, 41, 28, 1);
    }
    .shim-red::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(233, 233, 233, 1) 0,
        rgba(233, 233, 233, 0.9) 50%,
        rgba(233, 233, 233, 0.8) 100%
      );
      animation: shimmer 3s ease-out infinite;
      content: '';
    }
    @keyframes shimmer {
      100% {
        transform: translateX(0%);
        opacity: 0;
      }
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarComponent {
  @Input() color?: Color = 'blue'; // default to blue
}

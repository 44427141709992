import { Injectable, InjectionToken, inject } from '@angular/core';

export interface FileApiHeadersStatic {
  product: string;
  subscriptionKey: string;
  userPrincipalId: string;
}

export interface FileApiHeaders extends FileApiHeadersStatic {
  correlationId: string;
}

export const FILE_API_HEADERS_STATIC = new InjectionToken<FileApiHeadersStatic>(
  'FILE_API_HEADERS_STATIC'
);

@Injectable({
  providedIn: 'root',
})
export class FileApiHeadersService {
  private readonly fileApiHeadersStatic = inject(FILE_API_HEADERS_STATIC);

  get defaultHeaders(): FileApiHeaders {
    return {
      ...this.fileApiHeadersStatic,
      correlationId: crypto.randomUUID(),
    };
  }
}

import { Injectable, InjectionToken, inject } from '@angular/core';

export interface ClaimApiHeadersStatic {
  product: string;
  subscriptionKey: string;
  userPrincipalId: string;
}

export interface ClaimApiHeaders extends ClaimApiHeadersStatic {
  correlationId: string;
}

export const CLAIM_API_HEADERS_STATIC =
  new InjectionToken<ClaimApiHeadersStatic>('CLAIM_API_HEADERS_STATIC');

@Injectable({
  providedIn: 'root',
})
export class ClaimApiHeadersService {
  private readonly claimApiHeadersStatic = inject(CLAIM_API_HEADERS_STATIC);

  get defaultHeaders(): ClaimApiHeaders {
    return {
      ...this.claimApiHeadersStatic,
      correlationId: crypto.randomUUID(),
    };
  }
}

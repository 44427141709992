import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'integral-error-message',
  standalone: true,
  imports: [],
  template: `
    <div class=" w-full p-4 bg-errorLight rounded-lg text-errorDark">
      <div class="flex w-full justify-start items-start gap-4">
        <span class="pt-[1px]">
          <svg width="24" height="24" class="fill-current text-errorDark">
            <use href="/assets/svg/error-x.svg#root" />
          </svg>
        </span>
        <div class="flex-col flex gap-2">
          <h5 class="text-xl">
            {{ title }}
          </h5>
          <div class="w-full">
            <p [innerHTML]="description"></p>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorMessageComponent {
  @Input({ required: true }) title = 'Error';
  // optional description
  @Input() description = '';
}

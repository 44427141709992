import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ControlBaseAbstractDirective,
  formViewProvider,
} from './control-base-abstract.directive';

@Component({
  selector: 'integral-checkbox',
  standalone: true,
  template: `
    <div class="flex flex-nowrap items-center gap-6">
      <input
        [id]="cfg.name"
        type="checkbox"
        class="appearance-none border border-gray-300 rounded text-gray-900 leading-tight checked:bg-primary checked:border-primary700"
        [formControlName]="cfg.name"
      />
      @if (cfg.label) {
        <label class="items-center" [for]="cfg.name">{{ cfg.label }}</label>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule],
  viewProviders: [formViewProvider], // needed so this can be registered to a parent form
})
export class CheckboxComponent extends ControlBaseAbstractDirective {}

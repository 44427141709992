import { JsonPipe } from '@angular/common';
import { Component, inject, isDevMode } from '@angular/core';
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import {
  LossEventComponent,
  LossEventForm,
} from './pages/loss-event.component';
import {
  ClaimDescriptionComponent,
  ClaimDescriptionForm,
} from './pages/claim-description.component';
import {
  InvoiceDetailsComponent,
  InvoiceDetailsForm,
} from './pages/invoice-details.component';
import {
  ClaimApi,
  LossEventType,
  UplodedFileDetails,
} from '@integral/shared/backends/claim';
import {
  UploadedFilesComponent,
  UploadedFilesForm,
} from './pages/uploaded-files.component';
import {
  TransferAndConsentComponent,
  TransferAndConsentForm,
} from './pages/transfer-and-consent.component';
import { ClaimEndpageComponent } from './pages/claim-endpage.component';
import {
  dateNotExistingValidator,
  invalidSlovenianIban,
  invalidValueValidator,
  notInTheFutureValidator,
  validateIban,
} from '@integral/shared/util';
import {
  FooterComponent,
  LamieHeaderComponent,
  LamieStepperComponent,
  LoadingOverlayComponent,
} from '@integral/shared/ui/layout';
import {
  FullStepPersonalDataComponent,
  FullStepVerifyNumberComponent,
  NumberVerificationForm,
  PersonalDataForm,
} from '@integral/shared/full-step-pages';

type ClaimForm = FormGroup<{
  step1: NumberVerificationForm;
  step2: LossEventForm;
  step3: ClaimDescriptionForm;
  step4: InvoiceDetailsForm;
  step5: UploadedFilesForm;
  step6: PersonalDataForm;
  step7: TransferAndConsentForm;
}>;

@Component({
  standalone: true,
  imports: [
    JsonPipe,
    TranslateModule,
    FormsModule, // for ngModel of debug selct0r
    LoadingOverlayComponent,
    LamieHeaderComponent,
    LamieStepperComponent,
    FooterComponent,
  ],
  selector: 'app-root',
  template: `
    <div class="w-full min-h-screen bg-white relative">
      <integral-loading-overlay>
        <lamie-header />
        <lamie-stepper
          [startIdx]="startIdx"
          [steps]="steps"
          [endButtonIndex]="endButtonIndex"
        >
        </lamie-stepper>
      </integral-loading-overlay>
      <integral-footer />
    </div>

    @if (showDebugInfo) {
      <button class="border border-black bg-errorLight" (click)="fillForm()">
        wo ist kevin?</button
      ><br />
      <select [(ngModel)]="startIdx">
        @for (step of steps; track $index) {
          <option [ngValue]="$index">{{ $index + 1 }}</option>
        }
      </select>
      <!--      {{ form.getRawValue() | json }}-->
    }
  `,
})
export class AppComponent {
  private readonly nnfb = inject(NonNullableFormBuilder);
  private readonly translate = inject(TranslateService);
  protected showDebugInfo = isDevMode();
  protected startIdx = 0;
  protected endButtonIndex = 6;

  protected readonly form: ClaimForm = this.nnfb.group({
    step1: this.nnfb.group({
      customerIdentifier: ['', Validators.required],
      captchaSolution: ['', Validators.required],
      claimEligibilityInfo: this.nnfb.group({
        coverFrom: ['' as string | null | undefined, Validators.required],
        insuranceContractType: [
          '' as string | null | undefined,
          Validators.required,
        ],
      }),
      verificationCode: ['', Validators.required],
      token: [{ value: '', disabled: true }, Validators.required],
    }) as NumberVerificationForm,
    step2: this.nnfb.group({
      lossEvent: [undefined as LossEventType | undefined, Validators.required],
    }) as LossEventForm,
    step3: this.nnfb.group({
      city: ['', Validators.required],
      country: ['', [Validators.required, invalidValueValidator('si')]],
      claimDate: ['', [Validators.required]],
      lossEventDescription: ['', Validators.required],
      estimatedCosts: this.nnfb.group({
        currency: ['EUR'],
        amount: [
          undefined as number | undefined,
          [Validators.required, Validators.min(0)],
        ],
      }),
    }) as ClaimDescriptionForm,
    step4: this.nnfb.group({
      upload: [undefined as undefined | File],
      fileInfos: this.nnfb.group({
        InvoiceDate: [
          '',
          [
            Validators.required,
            notInTheFutureValidator(),
            dateNotExistingValidator(),
          ],
        ],
        Amount: ['', Validators.required],
        Currency: ['', Validators.required],
        InvoiceType: ['', Validators.required],
      }),
      uploadedFiles: [[] as UplodedFileDetails[]],
    }) as InvoiceDetailsForm,
    step5: this.nnfb.group({
      upload: [undefined as undefined | File],
      uploadedFiles: [[] as string[]],
    }) as UploadedFilesForm,
    step6: this.nnfb.group({
      claimAffectedPerson: this.nnfb.group({
        gender: [undefined as ClaimApi.Gender | undefined, Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        addressLine1: ['', Validators.required],
        postalCode: ['', Validators.required],
        city: ['', Validators.required],
        dateOfBirth: [
          '',
          [
            Validators.required,
            notInTheFutureValidator(),
            dateNotExistingValidator(),
          ],
        ],
        nationalIdentificationNumber: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        phoneNumber: ['', Validators.required],
        type: [undefined as ClaimApi.ClaimAffectedPersonType | undefined],
      }),
      contractHolder: [undefined as ClaimApi.ContractHolderDto | undefined],
    }) as PersonalDataForm,
    step7: this.nnfb.group({
      bankAccountHolder: ['', Validators.required],
      iban: [
        '',
        [Validators.required, validateIban(), invalidSlovenianIban('SI')],
      ],
      noBankDataConsent: [false],
      consentRoamingOk: [false, Validators.requiredTrue],
      consentTBD: [false, Validators.requiredTrue],
      claimNumber: ['' as string | null | undefined],
    }) as TransferAndConsentForm,
  });

  protected readonly steps = [
    {
      componentClass: FullStepVerifyNumberComponent,
      componentInputs: {
        stepControl: this.form.controls.step1,
      },
    },
    {
      componentClass: LossEventComponent,
      componentInputs: {
        stepControl: this.form.controls.step2,
      },
    },
    {
      componentClass: ClaimDescriptionComponent,
      componentInputs: {
        stepControl: this.form.controls.step3,
      },
    },
    {
      componentClass: InvoiceDetailsComponent,
      componentInputs: {
        stepControl: this.form.controls.step4,
      },
    },
    {
      componentClass: UploadedFilesComponent,
      componentInputs: {
        stepControl: this.form.controls.step5,
      },
    },
    {
      componentClass: FullStepPersonalDataComponent,
      componentInputs: {
        stepControl: this.form.controls.step6,
      },
    },
    {
      componentClass: TransferAndConsentComponent,
      componentInputs: {
        stepControl: this.form.controls.step7,
      },
    },
    {
      componentClass: ClaimEndpageComponent,
      componentInputs: {
        stepControl: this.form.controls.step7.controls.claimNumber,
      },
    },
  ];

  constructor() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');
  }

  fillForm() {
    this.form.patchValue(kevin);
  }
}

//TODO remove kevin helper
export const kevin = {
  /*  step1: {
    customerIdentifier: '',
    claimEligibilityInfo: {
      coverFrom: '2023-12-12T00:00:00+01:00',
      insuranceContractType: 'Travel_Insurance_A1_SVN_Basic',
    },
    verificationCode: 'lamie-ok',
    token: 'Dummy',
  },*/
  step2: { lossEvent: LossEventType.lostStolenDocuments },
  step3: {
    city: 'TestCity',
    country: 'at',
    claimDate: '2023-12-17',
    lossEventDescription: 'loss event description',
    estimatedCosts: { currency: 'EUR', amount: 150 },
  },
  step4: {
    upload: undefined,
  },
  step5: {},
  step6: {
    claimAffectedPerson: {
      gender: 'Female' as ClaimApi.Gender,
      firstName: 'Jane',
      lastName: 'Doe',
      addressLine1: 'Muster Street 17',
      postalCode: '1234',
      city: 'TestCity',
      dateOfBirth: '1995-12-17',
      nationalIdentificationNumber: '1234',
      email: 'jane.doe@gmail.com',
      phoneNumber: '+436644444444',
      type: 'ContractUser' as ClaimApi.ClaimAffectedPersonType,
    },
    contractHolder: {
      gender: 'Male' as ClaimApi.Gender,
      firstName: 'joe',
      lastName: 'ContractHolder',
      addressLine1: 'Muster Street 17',
      postalCode: '1234',
      city: 'TestCity',
      dateOfBirth: '1995-12-17',
      nationalIdentificationNumber: '1234',
    },
  },
  step7: {
    bankAccountHolder: 'jane doe',
    iban: 'SI8888888888',
    noBankDataConsent: true,
    consentRoamingOk: true,
    consentTBD: true,
  },
};

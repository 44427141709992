import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  AsyncPipe,
  NgClass,
  TitleCasePipe,
  UpperCasePipe,
} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'integral-page-header',
  standalone: true,
  imports: [AsyncPipe, TranslateModule, NgClass, UpperCasePipe, TitleCasePipe],
  template: `
    @if (isSchadensmeldung) {
      <header class="pb-4 ">
        <div
          class="bg-black border-t border-b border-black md:absolute w-full text-right py-3"
        >
          <div class="container mx-auto">
            <button
              class="mx-2 text-white hover:text-gray-300 leading-none"
              [ngClass]="{
                'font-bold underline underline-offset':
                  currentLanguage === secondLanguage
              }"
              (click)="onChangeLanguage(secondLanguage)"
            >
              {{ jsonRefSecondLanguage | translate | uppercase }}
            </button>
            <button
              class="mx-2 text-white hover:text-gray-300 leading-none"
              [ngClass]="{
                'font-bold underline underline-offset':
                  currentLanguage === english
              }"
              (click)="onChangeLanguage(english)"
            >
              {{ jsonRefEnglish | translate | uppercase }}
            </button>
            @if (isSchadensmeldung && (isLoggedIn$ | async) === true) {
              <button
                class="mx-2"
                (click)="onLogoutClick()"
                [title]="'header.logoutTitle' | translate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-4 h-4 text-white hover:text-gray-300"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                  />
                </svg>
              </button>
            }
          </div>
        </div>

        <div
          class="container mx-auto z-20 sm:flex sm:flex-row sm:justify-between"
        >
          <a [href]="logoLink" class="w-full">
            <div
              class="flex flex-wrap w-full gap-4 justify-between md:justify-start px-2 items-center"
            >
              <div class="z-10">
                <img
                  src="assets/{{ logoSrc }}"
                  width="149"
                  height="147"
                  alt="A1 logo"
                  class="border border-gray-300 border-b-0 "
                />
              </div>
              <div class="flex flex-col justify-start md:pt-8">
                <div>
                  <img
                    src="assets/{{ productLogoSrc }}"
                    srcset="assets/{{productLogoSrcSet}} 437w"
                    sizes="100vw"
                    width="218"
                    height="36"
                    style="height: 36px"
                    class="mb-6 ml-10"
                    alt="A1 product logo"
                  />
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="flex flex-row text-center items-center justify-center ">
          <hr class="w-full border-gray-300" />
        </div>
      </header>
    } @else {
      <header class="w-full min-h-28 mb-8">
        <div
          id="row1"
          class="text-right bg-primary min-h-14 max-h-14 h-14 py-4 box-border"
        >
          <div class="mx-auto text-white">
            <button
              class="mx-2 hover:text-gray-300 leading-none"
              [ngClass]="{
                'font-bold underline underline-offset':
                  currentLanguage === secondLanguage
              }"
              (click)="onChangeLanguage(secondLanguage)"
            >
              {{ jsonRefSecondLanguage | translate | uppercase }}
            </button>
            <button
              class="mx-2 hover:text-gray-300 leading-none"
              [ngClass]="{
                'font-bold underline underline-offset':
                  currentLanguage === english
              }"
              (click)="onChangeLanguage(english)"
            >
              {{ jsonRefEnglish | translate | uppercase }}
            </button>
          </div>
        </div>
        <div id="row2" class="md:mt-[-3.5rem]">
          <div
            id="logos"
            class="lg:ml-[10%] flex justify-start flex-nowrap gap-4"
          >
            <div
              class="flex flex-col justify-start bg-white px-2 py-14 z-10 border-x-2 border-neutral-200 border-b-0 border-t-[1px] md:my-auto m-0"
            >
              <a [href]="logoLink" class="w-full">
                <div class="z-10 mb-4">
                  <img src="assets/{{ logoSrc }}" width="200" alt="logo" />
                </div>
              </a>
            </div>
            <div class="flex flex-col justify-center items-end gap-2 md:mt-14">
              <div class="pr-1 text-left text-2xl">
                {{ 'header.product.name' | translate | titlecase }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="flex flex-row text-center items-center justify-center shadow-header"
        >
          <hr class="w-full border-neutral-200 border-b-[1px]" />
        </div>
      </header>
    }
  `,
  styles: `
    .shadow-header {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1) !important;
    }

    :host {
      display: block;
    }
    .underline-offset {
      text-underline-offset: 8px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() isSchadensmeldung = false;
  @Input() isLoggedIn$: Observable<boolean> = of(false);
  @Input() english = 'en';
  @Input() secondLanguage = 'en'; // english as fallback value
  @Input() jsonRefEnglish = 'en';
  @Input() jsonRefSecondLanguage = 'en';
  @Input() logoLink = '#';
  @Input() logoSrc = 'insertLogoSrc';
  @Input() logoSrcSet = 'insertLogoSrc';
  @Input() productLogoSrc = '';
  @Input() productLogoSrcSet = '';
  @Input() currentLanguage = 'en';
  @Input() public logoutCallback?: () => unknown | Observable<unknown>;
  @Output() public logout = new EventEmitter<void>();
  @Output() public newLang: EventEmitter<string> = new EventEmitter<string>();

  onChangeLanguage(newLang: string): void {
    this.newLang.emit(newLang);
  }

  public onLogoutClick(): void {
    this.handleOnClick(this.logoutCallback, this.logout);
  }

  public handleOnClick(
    callback?: () => unknown | Observable<unknown>,
    output?: EventEmitter<void>,
  ): void {
    if (!callback || !output) {
      return;
    } else {
      callback();
      output.emit();
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'integral-footer',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  template: ` <footer class="bg-primary text-gray-50 w-full py-20 px-5 mt-14">
    <div class="w-full mx-auto flex flex-col gap-8">
      <div class="w-full"><p [innerHTML]="'footer.intro' | translate"></p></div>
      <div class="w-full">
        <p
          class="font-bold pb-6"
          [innerHTML]="'footer.contactTitle' | translate"
        ></p>
        <p [innerHTML]="'footer.contact' | translate"></p>
      </div>
      <div class="w-full">
        <p
          class="font-bold pb-6"
          [innerHTML]="'footer.agentTitle' | translate"
        ></p>
        <p [innerHTML]="'footer.agent' | translate"></p>
        <p class="underline">
          <a
            href="https://www.lamie-direkt.at/en/data-protection/"
            [innerHTML]="'footer.privacyPolicy' | translate"
          >
          </a>
        </p>
      </div>
      <div class="w-full">
        <p
          class="font-bold pb-6"
          [innerHTML]="'footer.insurerTitle' | translate"
        ></p>
        <p [innerHTML]="'footer.insurer' | translate"></p>
      </div>
    </div>
  </footer>`,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {}

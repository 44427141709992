/**
 * Claim Services API
 * The Claim Services API provides methods for creating and managing claims.
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { ClaimCreationTokenDto } from '../model/claimCreationTokenDto';
// @ts-ignore
import { ClaimEligibilityDto } from '../model/claimEligibilityDto';
// @ts-ignore
import { CreateClaimRequestDto } from '../model/createClaimRequestDto';
// @ts-ignore
import { CreatedClaimDto } from '../model/createdClaimDto';
// @ts-ignore
import { ErrorResponse } from '../model/errorResponse';
// @ts-ignore
import { PersonalDataDto } from '../model/personalDataDto';
// @ts-ignore
import { ValidateVerificationCodeRequestDto } from '../model/validateVerificationCodeRequestDto';
// @ts-ignore
import { VerifyEligibilityRequestDto } from '../model/verifyEligibilityRequestDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateRequestParams {
    product: string;
    lamieToken: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
    createClaimRequestDto: CreateClaimRequestDto;
}

export interface GetAffectedPersonInfoRequestParams {
    customerIdentifier: string;
    product: string;
    lamieToken: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
}

export interface ValidateVerificationCodeRequestParams {
    product: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
    validateVerificationCodeRequestDto: ValidateVerificationCodeRequestDto;
}

export interface VerifyEligibilityRequestParams {
    product: string;
    captchaSolution: string;
    /** Unique subscription-key to identify user */
    subscriptionKey: string;
    /** Unique correlationId to identify requests */
    correlationId: string;
    /** Id to identify the user */
    userPrincipalId: string;
    verifyEligibilityRequestDto: VerifyEligibilityRequestDto;
}


@Injectable({
  providedIn: 'root'
})
export class ClaimAggregationApiService {

    protected basePath = 'http://localhost';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public create(requestParameters: CreateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CreatedClaimDto>;
    public create(requestParameters: CreateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CreatedClaimDto>>;
    public create(requestParameters: CreateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CreatedClaimDto>>;
    public create(requestParameters: CreateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling create.');
        }
        const lamieToken = requestParameters.lamieToken;
        if (lamieToken === null || lamieToken === undefined) {
            throw new Error('Required parameter lamieToken was null or undefined when calling create.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling create.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling create.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling create.');
        }
        const createClaimRequestDto = requestParameters.createClaimRequestDto;
        if (createClaimRequestDto === null || createClaimRequestDto === undefined) {
            throw new Error('Required parameter createClaimRequestDto was null or undefined when calling create.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (lamieToken !== undefined && lamieToken !== null) {
            localVarHeaders = localVarHeaders.set('lamie-token', String(lamieToken));
        }
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/claim/api/v1/claims/Create`;
        return this.httpClient.request<CreatedClaimDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: createClaimRequestDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAffectedPersonInfo(requestParameters: GetAffectedPersonInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PersonalDataDto>;
    public getAffectedPersonInfo(requestParameters: GetAffectedPersonInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PersonalDataDto>>;
    public getAffectedPersonInfo(requestParameters: GetAffectedPersonInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PersonalDataDto>>;
    public getAffectedPersonInfo(requestParameters: GetAffectedPersonInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const customerIdentifier = requestParameters.customerIdentifier;
        if (customerIdentifier === null || customerIdentifier === undefined) {
            throw new Error('Required parameter customerIdentifier was null or undefined when calling getAffectedPersonInfo.');
        }
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling getAffectedPersonInfo.');
        }
        const lamieToken = requestParameters.lamieToken;
        if (lamieToken === null || lamieToken === undefined) {
            throw new Error('Required parameter lamieToken was null or undefined when calling getAffectedPersonInfo.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling getAffectedPersonInfo.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling getAffectedPersonInfo.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling getAffectedPersonInfo.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (customerIdentifier !== undefined && customerIdentifier !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>customerIdentifier, 'customerIdentifier');
        }
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (lamieToken !== undefined && lamieToken !== null) {
            localVarHeaders = localVarHeaders.set('lamie-token', String(lamieToken));
        }
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/claim/api/v1/claims/GetAffectedPersonInfo`;
        return this.httpClient.request<PersonalDataDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateVerificationCode(requestParameters: ValidateVerificationCodeRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ClaimCreationTokenDto>;
    public validateVerificationCode(requestParameters: ValidateVerificationCodeRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ClaimCreationTokenDto>>;
    public validateVerificationCode(requestParameters: ValidateVerificationCodeRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ClaimCreationTokenDto>>;
    public validateVerificationCode(requestParameters: ValidateVerificationCodeRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling validateVerificationCode.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling validateVerificationCode.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling validateVerificationCode.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling validateVerificationCode.');
        }
        const validateVerificationCodeRequestDto = requestParameters.validateVerificationCodeRequestDto;
        if (validateVerificationCodeRequestDto === null || validateVerificationCodeRequestDto === undefined) {
            throw new Error('Required parameter validateVerificationCodeRequestDto was null or undefined when calling validateVerificationCode.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }

        let localVarHeaders = this.defaultHeaders;
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/claim/api/v1/claims/ValidateVerificationCode`;
        return this.httpClient.request<ClaimCreationTokenDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: validateVerificationCodeRequestDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyEligibility(requestParameters: VerifyEligibilityRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<ClaimEligibilityDto>;
    public verifyEligibility(requestParameters: VerifyEligibilityRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<ClaimEligibilityDto>>;
    public verifyEligibility(requestParameters: VerifyEligibilityRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<ClaimEligibilityDto>>;
    public verifyEligibility(requestParameters: VerifyEligibilityRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const product = requestParameters.product;
        const captchaSolution = requestParameters.captchaSolution;
        if (product === null || product === undefined) {
            throw new Error('Required parameter product was null or undefined when calling verifyEligibility.');
        }
        const subscriptionKey = requestParameters.subscriptionKey;
        if (subscriptionKey === null || subscriptionKey === undefined) {
            throw new Error('Required parameter subscriptionKey was null or undefined when calling verifyEligibility.');
        }
        const correlationId = requestParameters.correlationId;
        if (correlationId === null || correlationId === undefined) {
            throw new Error('Required parameter correlationId was null or undefined when calling verifyEligibility.');
        }
        const userPrincipalId = requestParameters.userPrincipalId;
        if (userPrincipalId === null || userPrincipalId === undefined) {
            throw new Error('Required parameter userPrincipalId was null or undefined when calling verifyEligibility.');
        }
        const verifyEligibilityRequestDto = requestParameters.verifyEligibilityRequestDto;
        if (verifyEligibilityRequestDto === null || verifyEligibilityRequestDto === undefined) {
            throw new Error('Required parameter verifyEligibilityRequestDto was null or undefined when calling verifyEligibility.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (product !== undefined && product !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>product, 'product');
        }
        if (captchaSolution !== undefined && captchaSolution !== null) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
              <any>captchaSolution, 'captchaSolution');
          }

        let localVarHeaders = this.defaultHeaders;
        if (subscriptionKey !== undefined && subscriptionKey !== null) {
            localVarHeaders = localVarHeaders.set('subscription-key', String(subscriptionKey));
        }
        if (correlationId !== undefined && correlationId !== null) {
            localVarHeaders = localVarHeaders.set('correlationId', String(correlationId));
        }
        if (userPrincipalId !== undefined && userPrincipalId !== null) {
            localVarHeaders = localVarHeaders.set('userPrincipalId', String(userPrincipalId));
        }

        let localVarCredential: string | undefined;
        // authentication (Bearer) required
        localVarCredential = this.configuration.lookupCredential('Bearer');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/claim/api/v1/claims/VerifyEligibility`;
        return this.httpClient.request<ClaimEligibilityDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: verifyEligibilityRequestDto,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}

import { Component, Input } from '@angular/core';
import { LamieStepperPageBaseComponent } from '@integral/shared/ui/layout';
import { LossEventType } from '@integral/shared/backends/claim';
import { TranslateModule } from '@ngx-translate/core';

import { RadioGroupComponent } from '@integral/shared/ui/form';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

export type LossEventForm = FormGroup<{
  lossEvent: FormControl<undefined | LossEventType>;
}>;
@Component({
  standalone: true,
  selector: 'app-loss-event',
  template: `
    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <h1 class="text-primary">{{ 'incident.title' | translate }}</h1>
      <p>{{ 'incident.text' | translate }}</p>
      <integral-radio-group
        [cfg]="{
          name: 'lossEvent'
        }"
        [options]="lossEventOptions"
      />
    </form>
  `,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
  `,
  imports: [ReactiveFormsModule, TranslateModule, RadioGroupComponent],
})
export class LossEventComponent extends LamieStepperPageBaseComponent {
  @Input({ required: true }) override stepControl!: LossEventForm;

  protected readonly lossEventOptions = Object.keys(LossEventType).map(
    (lossEventType) => {
      return {
        value: lossEventType,
        label: `incident.lossEventType.${lossEventType}`,
      };
    },
  );

  override onPrev = () => {
    this.stepControl.parent?.reset();
    window.location.reload();
    return true;
  };
}

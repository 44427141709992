import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as IBAN from 'iban';

export function invalidValueValidator(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value === value ? { invalidValue: true } : null;
  };
}

export function invalidSlovenianIban(value: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value.startsWith(value)
      ? { invalidSlovenianIban: true }
      : null;
  };
}

export function notInTheFutureValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const today = new Date().getTime();
    const controlDate = new Date(control.value).getTime();
    return controlDate > today ? { dateInTheFuture: true } : null;
  };
}

export function dateNotElligibleValidator(coverFrom: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const contractDate = new Date(Date.parse(coverFrom));
    contractDate.setDate(contractDate.getDate() + 1);
    const controlDate = new Date(control.value).getTime();
    return controlDate < contractDate.getTime()
      ? { dateNotElligible: true }
      : null;
  };
}

export function dateNotExistingValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const controlDate = new Date(control.value).getTime();
    return isNaN(controlDate) ? { dateNotExisting: true } : null;
  };
}

export function validateIban(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && !IBAN.isValid(control.value)) {
      return { iban: { value: control.value } };
    }
    return null;
  };
}

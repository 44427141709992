export type LossEventType =
  | 'sportsLiability'
  | 'searchRescue'
  | 'luggage'
  | 'stolenLostDevice';

export const LossEventType = {
  travelLiability: 'travelLiability' as LossEventType,
  searchRescue: 'searchRescue' as LossEventType,
  luggage: 'luggage' as LossEventType,
  lostStolenDocuments: 'lostStolenDocuments' as LossEventType,
};

export type FileInfo = {
  InvoiceDate: string;
  Amount: string;
  Currency: string;
  InvoiceType: string;
};

export type UplodedFileDetails = {
  fileName: string;
  fileInfos: FileInfo;
};

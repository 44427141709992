import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import {
  ButtonComponent,
  ErrorMessageComponent,
  LamieStepperPageBaseComponent,
  LoadingOverlayStateService,
} from '@integral/shared/ui/layout';
import {
  ClaimApi,
  ClaimApiHeadersService,
} from '@integral/shared/backends/claim';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, finalize, of, Subject, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  SelectBoxComponent,
  TextInputComponent,
} from '@integral/shared/ui/form';
import { TranslateModule } from '@ngx-translate/core';
import { PersonalDataForm } from './steps-types';

@Component({
  selector: 'full-step-personal-data',
  standalone: true,
  imports: [
    ErrorMessageComponent,
    FormsModule,
    ReactiveFormsModule,
    SelectBoxComponent,
    TextInputComponent,
    TranslateModule,
    ButtonComponent,
  ],
  template: ` <dialog
      #consentDialog
      class="p-5 rounded max-w-2xl"
      role="alertdialog"
    >
      <div
        class="relative flex w-full max-w-screen-md px-4 py-4 text-base rounded-lg font-regular"
      >
        <span
          class="!absolute top-3 left-3 h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg text-center align-middle text-xs font-medium uppercase transition-all"
        >
          <span
            class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-error600"
          >
            <svg width="30" height="30" class="fill-current text-error600">
              <use href="/assets/svg/info.svg#root" />
            </svg>
          </span>
        </span>
        <div class="ml-12 mr-12">
          <h2
            class="block text-xl antialiased font-semibold leading-snug tracking-normal font-serif"
          >
            {{ 'dialog.title' | translate }}
          </h2>
          <p
            class="block mt-2 text-base antialiased font-normal leading-relaxed"
            [innerHTML]="'dialog.text' | translate"
          ></p>
        </div>
      </div>

      <div class="flex flex-row-reverse mt-4">
        <integral-button
          class="ml-4"
          [text]="'dialog.continueButton' | translate"
          (click)="onClickAccept()"
        >
          <svg height="18" width="18" postfix class="fill-current ml-2">
            <use href="/assets/svg/chevron-right.svg#root" />
          </svg>
        </integral-button>
      </div>
    </dialog>

    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <ng-container formGroupName="claimAffectedPerson">
        <h1 class="text-primary">{{ 'personalData.title' | translate }}</h1>

        @if (noContractHolderFound()) {
          <integral-error-message
            class="w-full"
            [title]="'dialog.error.title' | translate"
            [description]="'dialog.error.text' | translate"
          ></integral-error-message>
        } @else {
          <p>{{ 'personalData.text' | translate }}</p>
          <h2 class="mt-10">
            {{ 'personalData.personalInformation.heading' | translate }}
          </h2>
          <div class="flex flex-wrap justify-between gap-4 w-full">
            <div class="w-full lg:w-5/11 flex flex-col gap-6">
              <integral-select-box
                class="w-full"
                [cfg]="{
                  name: 'gender',
                  label:
                    'personalData.personalInformation.gender.label' | translate,
                  placeholder:
                    'personalData.personalInformation.gender.placeholder'
                    | translate
                }"
                [options]="genders"
              />
              <integral-text-input
                class="w-full"
                [cfg]="{
                  name: 'firstName',
                  label:
                    'personalData.personalInformation.firstName.label'
                    | translate,
                  placeholder:
                    'personalData.personalInformation.firstName.placeholder'
                    | translate
                }"
                [readonly]="isReadonly()"
              />
            </div>
            <div class="flex flex-col justify-end w-full lg:w-5/11">
              <integral-text-input
                class="w-full"
                [cfg]="{
                  name: 'lastName',
                  label:
                    'personalData.personalInformation.lastName.label'
                    | translate,
                  placeholder:
                    'personalData.personalInformation.lastName.placeholder'
                    | translate
                }"
                [readonly]="isReadonly()"
              />
            </div>
          </div>

          <h2 class="mt-10">
            {{ 'personalData.addressInformation.heading' | translate }}
          </h2>

          <div class="w-full flex flex-col gap-6">
            <div class="w-full">
              <integral-text-input
                class="w-full"
                [cfg]="{
                  name: 'addressLine1',
                  label:
                    'personalData.addressInformation.addressLine1.label'
                    | translate,
                  placeholder:
                    'personalData.addressInformation.addressLine1.placeholder'
                    | translate
                }"
              />
            </div>

            <div class="w-full flex flex-wrap justify-between gap-4 ">
              <integral-text-input
                class="w-full lg:w-5/11"
                [cfg]="{
                  name: 'postalCode',
                  label:
                    'personalData.addressInformation.postalCode.label'
                    | translate,
                  placeholder:
                    'personalData.addressInformation.postalCode.placeholder'
                    | translate
                }"
              />
              <integral-text-input
                class="w-full lg:w-5/11"
                [cfg]="{
                  name: 'city',
                  label:
                    'personalData.addressInformation.city.label' | translate,
                  placeholder:
                    'personalData.addressInformation.city.placeholder'
                    | translate
                }"
              />
            </div>
          </div>

          <h2 class="mt-10">
            {{ 'personalData.contactAndDateInformation.heading' | translate }}
          </h2>

          <div class="flex flex-wrap justify-between gap-4 w-full">
            <div class="w-full flex flex-wrap justify-between gap-4">
              <integral-text-input
                class="w-full lg:w-5/11"
                [cfg]="{
                  name: 'dateOfBirth',
                  label:
                    'personalData.contactAndDateInformation.dateOfBirth.label'
                    | translate,
                  placeholder:
                    'personalData.contactAndDateInformation.dateOfBirth.placeholder'
                    | translate
                }"
                [type]="'date'"
                [readonly]="isReadonly()"
              />
              <integral-text-input
                class="w-full lg:w-5/11"
                [cfg]="{
                  name: 'nationalIdentificationNumber',
                  label:
                    'personalData.contactAndDateInformation.nationalIdentificationNumber.label'
                    | translate,
                  placeholder:
                    'personalData.contactAndDateInformation.nationalIdentificationNumber.placeholder'
                    | translate
                }"
              />
            </div>

            <div class="w-full flex flex-wrap justify-between gap-4">
              <integral-text-input
                class="w-full lg:w-5/11"
                [cfg]="{
                  name: 'email',
                  label:
                    'personalData.contactAndDateInformation.email.label'
                    | translate,
                  placeholder:
                    'personalData.contactAndDateInformation.email.placeholder'
                    | translate
                }"
              />
              <integral-text-input
                class="w-full lg:w-5/11"
                [cfg]="{
                  name: 'phoneNumber',
                  label:
                    'personalData.contactAndDateInformation.phoneNumber.label'
                    | translate,
                  placeholder:
                    'personalData.contactAndDateInformation.phoneNumber.placeholder'
                    | translate
                }"
              />
              <input type="hidden" [formControlName]="'type'" />
            </div>
            @if (
              stepControl.controls.claimAffectedPerson.controls.dateOfBirth
                .dirty &&
              stepControl.controls.claimAffectedPerson.controls.dateOfBirth
                .errors?.['dateInTheFuture']
            ) {
              <integral-error-message
                class="w-full"
                [title]="'errorMessages.claimDateError01' | translate"
              ></integral-error-message>
            } @else if (
              stepControl.controls.claimAffectedPerson.controls.dateOfBirth
                .dirty &&
              stepControl.controls.claimAffectedPerson.controls.dateOfBirth
                .errors?.['dateNotExisting']
            ) {
              <integral-error-message
                class="w-full"
                [title]="'errorMessages.claimDateError02' | translate"
              ></integral-error-message>
            } @else if (
              stepControl.controls.claimAffectedPerson.controls.email.dirty &&
              stepControl.controls.claimAffectedPerson.controls.email.errors?.[
                'email'
              ]
            ) {
              <integral-error-message
                class="w-full"
                [title]="'errorMessages.emailError' | translate"
              ></integral-error-message>
            }
          </div>
        }
      </ng-container>
      <input type="hidden" [formControlName]="'contractHolder'" />
    </form>`,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullStepPersonalDataComponent
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  @ViewChild('consentDialog', { static: true })
  consentDialog?: ElementRef<HTMLDialogElement>;
  private readonly claimAggregationApiService = inject(
    ClaimApi.ClaimAggregationApiService,
  );
  private readonly claimApiHeadersService = inject(ClaimApiHeadersService);
  private readonly loaderService = inject(LoadingOverlayStateService);

  @Input({ required: true }) override stepControl!: PersonalDataForm;

  protected readonly genders = Object.keys(ClaimApi.Gender).map((key) => {
    return {
      value: key,
      label: `personalData.personalInformation.gender.${key.toLowerCase()}`,
    };
  });

  protected readonly isReadonly = signal(false);
  protected noContractHolderFound = signal(false);

  private readonly componentInitialised = new Subject<void>();
  private readonly dialogAccepted = new Subject<void>();

  private consentSubscription = this.componentInitialised
    .pipe(
      tap(() => {
        this.consentDialog?.nativeElement.showModal();
      }),
    )
    .pipe(takeUntilDestroyed())
    .subscribe();

  private personalDataSubscription = this.dialogAccepted
    .pipe(
      tap(() => {
        this.loaderService.showGlobalLoading();
      }),
      switchMap(() => {
        const formValue = this.stepControl.parent?.value;
        if (!formValue || !formValue.step1 || !formValue.step1.token) {
          this.setNoContractHolderFoundState();
          return of('token error');
        }
        const requestParams = {
          ...this.claimApiHeadersService.defaultHeaders,
          lamieToken: formValue.step1.token,
          customerIdentifier: formValue.step1.customerIdentifier,
        };

        return this.claimAggregationApiService
          .getAffectedPersonInfo(requestParams)
          .pipe(
            tap((response) => {
              if (
                response.affectedPerson &&
                response.affectedPersonType ===
                  ClaimApi.ClaimAffectedPersonType.ContractHolder
              ) {
                this.stepControl.controls.claimAffectedPerson.patchValue(
                  response.affectedPerson,
                );
                this.isReadonly.set(true);
              }
              if (response.contractHolder) {
                this.stepControl.controls.contractHolder.setValue(
                  response.contractHolder,
                );
                if (
                  !response.contractHolder.firstName ||
                  !response.contractHolder.lastName ||
                  !response.contractHolder.dateOfBirth
                ) {
                  this.setNoContractHolderFoundState();
                }
              }
              if (response.affectedPersonType) {
                this.stepControl.controls.claimAffectedPerson.controls.type.setValue(
                  response.affectedPersonType,
                );
              }
              if (
                response.affectedPersonType !==
                ClaimApi.ClaimAffectedPersonType.ContractHolder
              ) {
                this.setNoContractHolderFoundState();
              }
            }),
            catchError((e) => {
              this.setNoContractHolderFoundState();
              return of('no valid persons found');
            }),
            finalize(() => this.loaderService.hideGlobalLoading()),
          );
      }),
    )
    .pipe(takeUntilDestroyed())
    .subscribe();

  override ngOnInit() {
    super.ngOnInit();
    this.componentInitialised.next();
  }

  private setNoContractHolderFoundState() {
    this.noContractHolderFound.set(true);
    this._showNext.set(false);
    this.loaderService.hideGlobalLoading();
  }

  protected onClickAccept() {
    this.consentDialog?.nativeElement.close();
    this.dialogAccepted.next();
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  Input,
  OnInit,
  signal,
  ViewChild,
  WritableSignal,
} from '@angular/core';
import {
  ButtonComponent,
  ErrorMessageComponent,
  LamieStepperPageBaseComponent,
  LoadingOverlayStateService,
} from '@integral/shared/ui/layout';
import {
  ClaimApi,
  ClaimApiHeadersService,
} from '@integral/shared/backends/claim';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { TextInputComponent } from '@integral/shared/ui/form';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetInstance } from 'friendly-challenge';
import { NumberVerificationForm } from './steps-types';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'full-step-verify-number',
  standalone: true,
  imports: [
    ButtonComponent,
    ErrorMessageComponent,
    ReactiveFormsModule,
    TextInputComponent,
    TranslateModule,
    JsonPipe,
  ],
  template: `
    <form
      class="my-20 flex flex-col justify-between gap-5"
      [formGroup]="stepControl"
    >
      <h1 class="text-primary">
        {{ 'verifyNumber.headline' | translate }}
      </h1>

      @if (phoneErrorKey()) {
        <integral-error-message
          [title]="(phoneErrorKey() || '008') + '.title' | translate"
          [description]="
            (phoneErrorKey() || '008') + '.description' | translate
          "
        ></integral-error-message>
      } @else if (smsErrorKey()) {
        <integral-error-message [title]="smsErrorKey() || '004' | translate">
        </integral-error-message>
      } @else {
        <p [innerHTML]="'verifyNumber.intro' | translate"></p>
      }
      @if (userEligibile()) {
        <div class="w-2/3">
          <h2 class="py-5">
            {{ 'verifyNumber.explanationTitle' | translate }} <br />
            +{{ stepControl.controls.customerIdentifier.value }}
          </h2>
          <integral-text-input
            [cfg]="{
              name: 'verificationCode',
              label: 'verifyNumber.verificationCode' | translate,
              placeholder: 'verifyNumber.verificationPlaceholder' | translate
            }"
          />
        </div>
        <integral-button
          (click)="onResetEligibility()"
          [text]="'verifyNumber.correctNumber' | translate"
          [buttonStyle]="'Secondary'"
          class="text-error600"
        >
          <svg height="18" width="18" prefix class="fill-current mr-2">
            <use href="/assets/svg/chevron-left.svg#root" />
          </svg>
        </integral-button>
      } @else {
        <div class="w-full lg:w-2/3">
          <h2 class="py-5">
            {{ 'verifyNumber.explanation' | translate }}
          </h2>

          <div class="w-full flex justify-start items-end gap-2 flex-wrap">
            <input
              class="appearance-none border border-gray-300 rounded py-2 px-3 text-gray-900 leading-tight md:w-1/6 prefix text-right w-1/4"
              readonly
              placeholder="+386"
            />
            <integral-text-input
              class="w-full md:w-4/6"
              [cfg]="{
                name: 'customerIdentifier',
                label: 'verifyNumber.phoneNumber' | translate,
                placeholder: 'verifyNumber.phoneNumberPlaceholder' | translate
              }"
              [type]="'number'"
            />
          </div>
        </div>

        <integral-button
          [text]="'verifyNumber.verifyButtonLabel' | translate"
          [type]="'submit'"
          [disabled]="
            stepControl.controls.customerIdentifier.invalid ||
            stepControl.controls.captchaSolution.invalid
          "
          (click)="onVerifyEligibilityClick()"
        />

        <div class="w-full flex justify-end">
          <div
            class="frc-captcha"
            data-sitekey="FCMT7QF34N1JBS7H"
            data-start="auto"
            data-puzzle-endpoint="https://api.friendlycaptcha.com/api/v1/puzzle"
            #captcha
          ></div>
        </div>
      }
    </form>
  `,
  styles: `
    :host {
      display: block;
      width: 100%;
    }
    .prefix {
      height: fit-content;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullStepVerifyNumberComponent
  extends LamieStepperPageBaseComponent
  implements OnInit, AfterViewInit
{
  private readonly claimAggregationApiService = inject(
    ClaimApi.ClaimAggregationApiService,
  );
  private readonly claimApiHeadersService = inject(ClaimApiHeadersService);
  private readonly loaderService = inject(LoadingOverlayStateService);
  @Input({ required: true }) override stepControl!: NumberVerificationForm;
  @Input({ required: true }) countryPrefixActivated = false; // load from environment file

  private readonly verifyEligibilityClicked = new Subject<string>();

  private readonly verifyEligibility$ = this.verifyEligibilityClicked.pipe(
    switchMap((customerIdentifier) => {
      this.loaderService.showGlobalLoading();
      const verifyEligibilityRequestParams = {
        ...this.claimApiHeadersService.defaultHeaders,
        captchaSolution: this.stepControl.controls.captchaSolution.value,
        verifyEligibilityRequestDto: {
          customerIdentifier,
        },
      };

      return this.claimAggregationApiService
        .verifyEligibility(verifyEligibilityRequestParams)
        .pipe(
          tap(() => {
            this.phoneErrorKey.set(undefined);
          }),
          catchError((e: HttpErrorResponse) => {
            console.log('verifyEligibility Error', e);
            this.phoneErrorKey.set(
              this.getPhoneErrorKey(e?.error?.message || ''),
            );
            return of({});
          }),
          finalize(() => {
            this.loaderService.hideGlobalLoading();
          }),
        );
    }),
  );

  protected readonly userEligibile;
  protected smsErrorKey: WritableSignal<string | undefined> = signal(undefined);
  protected phoneErrorKey: WritableSignal<string | undefined> =
    signal(undefined);
  private readonly validPhoneErrorCode = /00[1-8]/;
  private readonly validSmsErrorCode = /00[1-4]/;
  private readonly initialised = new BehaviorSubject(false);
  private readonly showNextEffect;

  @ViewChild('captcha', { static: false })
  friendlyCaptcha!: ElementRef<HTMLElement>;
  constructor() {
    super();

    this.verifyEligibility$.pipe(takeUntilDestroyed()).subscribe((response) => {
      this.stepControl.controls.claimEligibilityInfo.patchValue(response);
    });

    const userEligible$ = this.initialised.pipe(
      filter((inited) => inited),
      switchMap(() => {
        return this.stepControl.controls.claimEligibilityInfo.statusChanges.pipe(
          map((status) => status === 'VALID'),
        );
      }),
    );

    this.userEligibile = toSignal(userEligible$, {
      initialValue: false,
    });

    this.showNextEffect = effect(
      () => {
        this._showNext.set(this.userEligibile());
      },
      { allowSignalWrites: true },
    );
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.initialised.next(true);
    this._showNext.set(false);
  }

  ngAfterViewInit() {
    this.startCaptcha();
  }

  startCaptcha() {
    const widget = new WidgetInstance(this.friendlyCaptcha.nativeElement, {
      doneCallback: (solution) =>
        this.stepControl.controls.captchaSolution.patchValue(solution),
    });

    widget.start();
  }
  override onNext = (): Observable<boolean> | boolean => {
    this.loaderService.showGlobalLoading();
    const validateVerificationCodeRequestDto = {
      customerIdentifier: this.stepControl.controls.customerIdentifier.value,
      verificationCode: this.stepControl.controls.verificationCode.value,
    };

    return this.claimAggregationApiService
      .validateVerificationCode({
        ...this.claimApiHeadersService.defaultHeaders,
        validateVerificationCodeRequestDto,
      })
      .pipe(
        tap(({ token }) => {
          if (token) {
            this.stepControl.controls.token.enable();
            this.stepControl.controls.token.setValue(token);
          }
        }),
        map(({ token }) => !!token),
        catchError((e: HttpErrorResponse) => {
          console.error(e);
          this.smsErrorKey.set(this.getSmsErrorKey(e.error.message));
          return of(false);
        }),
        finalize(() => this.loaderService.hideGlobalLoading()),
      );
  };

  private parseToSlovenianPhoneNumber(phone: string): string {
    const slovenianPrefix = '386';
    return slovenianPrefix + phone;
  }

  protected onVerifyEligibilityClick() {
    this.loaderService.showGlobalLoading();
    const slovenianNumber = this.parseToSlovenianPhoneNumber(
      this.stepControl.controls.customerIdentifier.value,
    );
    if (this.countryPrefixActivated) {
      this.verifyEligibilityClicked.next(slovenianNumber);
    } else {
      this.verifyEligibilityClicked.next(
        this.stepControl.controls.customerIdentifier.value,
      );
    }
  }

  private getPhoneErrorKey(code: string): string {
    return this.validPhoneErrorCode.test(code)
      ? `errorMessages.wrongNumber${code}`
      : 'errorMessages.wrongNumber008';
  }

  private getSmsErrorKey(code: string): string {
    return this.validSmsErrorCode.test(code)
      ? `errorMessages.sms${code}`
      : `errorMessages.sms004`;
  }

  onResetEligibility() {
    this.stepControl.reset();
    window.location.reload();
  }
}

import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type ButtonStyle = 'Primary' | 'Secondary';

@Component({
  standalone: true,
  selector: 'integral-button',
  template: `
    <button
      class="rounded-sm px-6 py-3 text-md transition-colors flex items-center shadow-sm"
      [ngClass]="[
        buttonStyle === 'Primary'
          ? disabled
            ? 'bg-neutral-400 text-white cursor-not-allowed'
            : 'bg-primary text-white  hover:bg-primaryHover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
          : disabled
            ? 'bg-neutral-400 text-white cursor-not-allowed'
            : ' bg-white text-gray-500 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
      ]"
      [type]="type"
      [disabled]="disabled"
    >
      <!-- use eg. for svg-icon -->
      <ng-content select="[prefix]"></ng-content>

      <span [innerHTML]="text"></span
      ><ng-content select="[postfix]"></ng-content>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass],
})
export class ButtonComponent {
  @Input() text = '';

  @Input() type: 'button' | 'submit' = 'button';

  @Input() buttonStyle: ButtonStyle = 'Primary';

  @Input() disabled = false;
}

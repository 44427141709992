import { Pipe, PipeTransform } from '@angular/core';
import * as IBAN from 'iban';

@Pipe({
  name: 'ibanFormatter',
  standalone: true,
})
export class IbanFormatterPipe implements PipeTransform {
  transform(
    value: string | null | undefined,
    formatOnlyIfIbanValid = false,
  ): string {
    if (!value) {
      return value as string;
    }
    if (formatOnlyIfIbanValid) {
      if (IBAN.isValid(value)) {
        return IBAN.printFormat(value, ' ');
      }
    } else {
      return IBAN.printFormat(value, ' ');
    }
    return value as string;
  }
}

import { Component, inject } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TitleCasePipe, UpperCasePipe } from '@angular/common';
import { PageHeaderComponent } from '../page-header.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lamie-header',
  standalone: true,
  template: `
    <integral-page-header
      secondLanguage="slv"
      jsonRefSecondLanguage="header.lang.slv"
      jsonRefEnglish="header.lang.en"
      logoSrc="lamie-logo-txt.jpg"
      [currentLanguage]="language"
      (newLang)="langChange($event)"
    ></integral-page-header>
  `,
  imports: [TranslateModule, UpperCasePipe, TitleCasePipe, PageHeaderComponent],
})
export class LamieHeaderComponent {
  private translate = inject(TranslateService);
  protected language = this.translate.getDefaultLang();

  langChange(event: string) {
    this.language = event;
    this.changeLanguage(this.language);
  }
  changeLanguage(newLang: string): void {
    this.translate.use(newLang);
  }
}

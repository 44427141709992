import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ControlBaseAbstractDirective,
  formViewProvider,
} from './control-base-abstract.directive';

export type TextInputType =
  | 'text'
  | 'number'
  | 'tel'
  | 'email'
  | 'search'
  | 'password'
  | 'date'
  | 'url';

@Component({
  selector: 'integral-text-input',
  standalone: true,
  template: `
    <div class="flex flex-col items-start">
      @if (cfg.label) {
        <label class="block mb-2" [for]="cfg.name">{{ cfg.label }}</label>
      }
      <input
        [id]="cfg.name"
        class="appearance-none border border-gray-300 rounded w-full py-2 px-3 text-gray-900 leading-tight"
        [formControlName]="cfg.name"
        [type]="type()"
        [placeholder]="cfg.placeholder ?? ''"
        [readonly]="readonly()"
      />
    </div>
  `,
  styles: `
    input::placeholder {
      opacity: 0.5;
      color: gray;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ReactiveFormsModule],
  viewProviders: [formViewProvider], // needed so this can be registered to a parent form
})
export class TextInputComponent extends ControlBaseAbstractDirective {
  type = input<TextInputType>('text');
  readonly = input(false);
}

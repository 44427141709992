import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  LamieStepperPageBaseComponent,
} from '@integral/shared/ui/layout';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-claim-end-page',
  standalone: true,
  imports: [TranslateModule, ButtonComponent],
  template: `
    @if (!stepControl.value) {
      <div
        class="flex-nowrap flex flex-col items-center my-20 gap-12 px-6 lg:px-20"
      >
        <div class="text-error600">
          <svg width="76" height="76" class="fill-current">
            <use href="/assets/svg/error-x.svg#root" />
          </svg>
        </div>
        <h1 class="text-3xl text-error600">
          {{ 'endpage.errorTitle' | translate }}
        </h1>
        <p
          [innerHTML]="'endpage.errorText' | translate"
          class="text-center leading-loose"
        ></p>
        <integral-button
          class="ml-4"
          [text]="'endpage.restartButtonLabel' | translate"
          (click)="onClickRestart()"
        ></integral-button>
      </div>
    } @else {
      <div
        class="flex-nowrap flex flex-col items-center my-20 gap-12 px-6 lg:px-20"
      >
        <div class="text-success600">
          <svg width="76" height="76" class="fill-current">
            <use href="/assets/svg/success.svg#root" />
          </svg>
        </div>
        <h1 class="text-3xl text-success600">
          {{ 'endpage.successTitle' | translate }}
        </h1>
        <p
          [innerHTML]="'endpage.successText' | translate"
          class="text-center leading-loose"
        ></p>
        <h2 [innerHTML]="'endpage.successClaimNumberInfo' | translate"></h2>
        <h1 class="text-errorDark">{{ stepControl.value }}</h1>
      </div>
    }
  `,
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimEndpageComponent
  extends LamieStepperPageBaseComponent
  implements OnInit
{
  @Input({ required: true }) override stepControl!: FormControl<
    string | undefined | null
  >;

  override ngOnInit() {
    super.ngOnInit();
    if (this.stepControl.value) {
      this._showPrev.set(false);
    }
  }

  protected onClickRestart() {
    window.location.reload();
  }
}
